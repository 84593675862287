import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  development: {
    apiKey: "AIzaSyAloTtIoIHnkx-jf4MKbrm1U3BRnn3yZN8",
    authDomain: "apinvoice-a11d2.firebaseapp.com",
    databaseURL: "https://apinvoice-a11d2.firebaseio.com",
    projectId: "apinvoice-a11d2",
    storageBucket: "apinvoice-a11d2.appspot.com",
    messagingSenderId: "53128644270"
  },
  production: {
    apiKey: "AIzaSyAloTtIoIHnkx-jf4MKbrm1U3BRnn3yZN8",
    authDomain: "apinvoice-a11d2.firebaseapp.com",
    databaseURL: "https://apinvoice-a11d2.firebaseio.com",
    projectId: "apinvoice-a11d2",
    storageBucket: "apinvoice-a11d2.appspot.com",
    messagingSenderId: "53128644270"
  }
};

firebase.initializeApp(firebaseConfig[process.env.REACT_APP_ENVIRONMENT]);

firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;

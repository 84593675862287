import React, { Component } from "react"
import { connect } from "react-redux"

function mapStateToProps(state) {
  return {}
}

class Timetracking extends Component {
  render() {
    return <div>Timetracking</div>
  }
}

export default connect(mapStateToProps)(Timetracking)

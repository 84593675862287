import http from "../../lib/httpService"

export const createCustomer = customer => {
  return async dispatch => {
    try {
      await http.put("/customers", customer)

      dispatch({ type: "CREATE_CUSTOMER" })
    } catch (err) {
      dispatch({ type: "CREATE_CUSTOMER_ERROR", payload: err })
    }
  }
}

export const getCustomer = customerId => {
  return async dispatch => {
    dispatch({ type: "GET_CUSTOMER" })
    try {
      const response = await http.get(`/customers/${customerId}`)
      dispatch({ type: "GET_CUSTOMER_FULFILLED", payload: response })
    } catch (err) {
      dispatch({ type: "GET_CUSTOMER_ERROR", payload: err })
    }
  }
}

export const updateCustomer = (customerId, customerData) => {
  return async dispatch => {
    try {
      const response = await http.post(`/customers/${customerId}`, customerData)
      dispatch({ type: "UPDATE_CUSTOMER", payload: response })
    } catch (err) {
      dispatch({ type: "UPDATE_CUSTOMER_ERROR", payload: err })
    }
  }
}

export const deleteCustomer = customerId => {
  return async dispatch => {
    try {
      const response = await http.delete(`/customers/${customerId}`)
      dispatch({ type: "DELETE_CUSTOMER", payload: response })
    } catch (err) {
      dispatch({ type: "DELETE_CUSTOMER_ERROR", payload: err })
    }
  }
}

export const getCustomers = () => {
  return async dispatch => {
    dispatch({ type: "GET_CUSTOMERS" })
    try {
      const response = await http.get("/customers")
      dispatch({ type: "GET_CUSTOMERS_FULFILLED", payload: response })
    } catch (err) {
      dispatch({ type: "GET_CUSTOMERS_ERROR", payload: err })
    }
  }
}

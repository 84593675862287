import React, { Component } from "react"
import {
  Table,
  Header,
  Icon,
  Button,
  Grid,
  Dimmer,
  Loader,
  Label,
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"

import { getDomains, renewDomain } from "../../redux/actions/domainActions"

const mapStateToProps = state => {
  return {
    domains: state.domain.domains,
    loading: state.domain.loading,
    firebaseProfile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDomains: () => dispatch(getDomains()),
    renewDomain: domainId => dispatch(renewDomain(domainId)),
  }
}

class Domains extends Component {
  componentWillMount() {
    this.props.getDomains()
  }

  render() {
    if (this.props.loading)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )
    const { domains } = this.props
    let counter = 1
    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">Domains</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/domains/new"} className="ui icon button">
              <Icon name="plus" />
            </Link>
          </Grid.Column>
        </Grid>

        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Domain</Table.HeaderCell>
              <Table.HeaderCell>Expire</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Hosting</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {domains.length > 0 &&
              domains.map((domain, id) => (
                <Table.Row key={domain.id}>
                  <Table.Cell>{counter++}</Table.Cell>
                  <Table.Cell>{domain.domain}</Table.Cell>
                  <Table.Cell>
                    {moment(domain.expireDate).format("DD.MM.YYYY")}
                  </Table.Cell>
                  <Table.Cell>{domain.customer}</Table.Cell>
                  <Table.Cell>
                    {!domain.hosting && (
                      <Label as="a" color="red" tag>
                        Domain Only
                      </Label>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      basic
                      color="green"
                      onClick={() =>
                        this.props.history.push(`/domains/${domain.id}`)
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      basic
                      color="blue"
                      onClick={() => this.props.renewDomain(domain.id)}
                    >
                      Renew
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Domains)

import React from "react"
import { Sidebar, Icon, Menu } from "semantic-ui-react"
import { withRouter, NavLink } from "react-router-dom"

const SideNavigation = () => {
  return (
    <Sidebar
      as={Menu}
      animation={"push"}
      direction={"left"}
      icon="labeled"
      vertical
      visible={true}
      width="thin"
    >
      <NavLink to="/dashboard" className="item">
        <Icon name="home" color="teal" />
        Dashboard
      </NavLink>
      <NavLink to="/customers" className="item">
        <Icon name="address book" color="teal" />
        Customers
      </NavLink>
      <NavLink to="/domains" className="item">
        <Icon name="server" color="teal" />
        Domains
      </NavLink>
      <NavLink to="/employees" className="item">
        <Icon name="users" color="teal" />
        Employees
      </NavLink>
      <NavLink to="/projects" className="item">
        <Icon name="compass" color="teal" />
        Projects
      </NavLink>
      <NavLink to="/timetracking" className="item">
        <Icon name="hourglass" color="teal" />
        Timetracking
      </NavLink>
      <NavLink to="/invoices" className="item">
        <Icon name="archive" color="teal" />
        Invoices
      </NavLink>
    </Sidebar>
  )
}

export default withRouter(SideNavigation)

import React, { Component } from "react"
import {
  Table,
  Header,
  Icon,
  Button,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { getCustomers } from "../../redux/actions/customerActions"

const mapStateToProps = state => {
  return {
    customers: state.customer.customers,
    loading: state.customer.loading,
    firebaseProfile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCustomers: () => dispatch(getCustomers()),
  }
}

class Customers extends Component {
  componentWillMount() {
    this.props.getCustomers()
  }

  render() {
    if (this.props.loading)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )
    const { customers } = this.props
    let counter = 1
    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">Customers</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/customers/new"} className="ui icon button">
              <Icon name="plus" />
            </Link>
          </Grid.Column>
        </Grid>

        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Contact Person</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {customers.length > 0 &&
              customers.map((customer, id) => (
                <Table.Row key={customer.id}>
                  <Table.Cell>{counter++}</Table.Cell>
                  <Table.Cell>{customer.companyName}</Table.Cell>
                  <Table.Cell>{customer.name}</Table.Cell>
                  <Table.Cell>{customer.email}</Table.Cell>
                  <Table.Cell>{customer.phone}</Table.Cell>
                  <Table.Cell>
                    <Button
                      basic
                      color="green"
                      onClick={() =>
                        this.props.history.push(`/customers/${customer.id}`)
                      }
                    >
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers)

const INITIAL_STATE = {
  created: false,
  removed: false,
  updated: false,
  loading: false,
  error: null,
  customers: [],
  customer: {},
}

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_CUSTOMERS":
      console.log("Loading customers")
      return { ...state, customers: {}, loading: true }
    case "GET_CUSTOMERS_FULFILLED":
      console.log("Get customers", action.payload)
      return {
        ...state,
        customers: action.payload,
        created: false,
        removed: false,
        updated: false,
        loading: false,
        error: null,
      }
    case "GET_CUSTOMERS_ERROR":
      console.log("Get customers ERROR", action.payload)
      return {
        ...state,
        customers: [],
        created: false,
        removed: false,
        updated: false,
        error: action.payload,
      }
    case "GET_CUSTOMER":
      console.log("Loading customer")
      return { ...state, customer: {}, loading: true }
    case "GET_CUSTOMER_FULFILLED":
      console.log("Get customer", action.payload)
      return { ...state, customer: action.payload, loading: false }
    case "CREATE_CUSTOMER":
      console.log("Created customer", action.payload)
      return { ...state, customer: {}, created: true }
    case "UPDATE_CUSTOMER":
      console.log("Employee updated", action.payload)
      return { ...state, customer: {}, updated: true }
    case "DELETE_CUSTOMER":
      console.log("Employee removed", action.payload)
      return { ...state, customer: {}, removed: true }
    case "CREATE_CUSTOMER_ERROR":
      console.log("Create customer error ", action.payload)
      return {
        ...state,
        created: { created: false, error: action.payload },
      }
    default:
      return state
  }
}

export default customerReducer

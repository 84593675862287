import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Header,
  Button,
  Form,
  Input,
  Grid,
  Loader,
  Dimmer,
  Icon,
  Select,
  Checkbox,
} from "semantic-ui-react"

import { DateInput } from "semantic-ui-calendar-react"
import moment from "moment"
import { Link } from "react-router-dom"

import {
  createDomain,
  getDomain,
  deleteDomain,
  updateDomain,
} from "../../../redux/actions/domainActions"

import { getCustomers } from "../../../redux/actions/customerActions"

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
    domainCreated: state.domain.created,
    domainRemoved: state.domain.removed,
    domainUpdated: state.domain.updated,
    domain: state.domain.domain,
    customers: state.customer.customers,
    loading: state.domain.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createDomain: domain => dispatch(createDomain(domain)),
    getDomain: domainId => dispatch(getDomain(domainId)),
    getCustomers: () => dispatch(getCustomers()),
    deleteDomain: domainId => dispatch(deleteDomain(domainId)),
    updateDomain: (domainId, payload) =>
      dispatch(updateDomain(domainId, payload)),
  }
}

class Domain extends Component {
  state = {
    domain: {
      domain: "",
      expireDate: moment()
        .add(1, "year")
        .format("DD.MM.YYYY"),
      hosting: false,
      customerId: null,
    },
    error: null,
    customers: [],
    existingDomain: false,
  }

  componentWillMount() {
    this.props.getCustomers()
    if (this.props.match.params.domainId) {
      this.props.getDomain(this.props.match.params.domainId)
      this.setState({ existingDomain: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.domainCreated ||
        nextProps.domainRemoved ||
        nextProps.domainUpdated
      ) {
        this.props.history.push("/domains")
      } else {
        if (this.props.domain !== nextProps.domain) {
          this.setState({
            domain: { ...nextProps.domain },
          })
        }
      }

      if (nextProps.customers.length > 0) {
        const customers = nextProps.customers.map(customer => {
          return {
            value: customer.id,
            text: customer.companyName,
          }
        })

        this.setState({
          customers: customers,
        })
      }
    }
  }

  saveDomain = e => {
    e.preventDefault()

    if (this.state.existingDomain) {
      this.props.updateDomain(
        this.props.match.params.domainId,
        this.state.domain
      )
    } else {
      this.props.createDomain(this.state.domain)
    }
  }

  deleteDomain = e => {
    e.preventDefault()
    this.props.deleteDomain(this.props.match.params.domainId)
  }

  handleChange = e => {
    this.setState({
      domain: {
        ...this.state.domain,
        [e.target.id]: e.target.value,
      },
      error: null,
    })
  }

  handleChangeSelect = (name, value) => {
    this.setState({
      domain: {
        ...this.state.domain,
        [name]: value,
      },
      error: null,
    })
  }

  handleChangeCheckbox = e => {
    const targetId = e.target.id
    this.setState(state => {
      return {
        domain: {
          ...state.domain,
          [targetId]: !state.domain[targetId],
        },
        error: null,
      }
    })
  }

  handleChangeDate = (e, { name, value }) => {
    const targetId = name
    this.setState(state => {
      return {
        domain: {
          ...state.domain,
          [targetId]: value,
        },
        error: null,
      }
    })
  }

  render() {
    if (this.props.loading || this.props.customers.length <= 0)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )

    const { domain } = this.state
    const pageTitle = this.state.existingDomain ? "Edit Domain" : "New Domain"

    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">{pageTitle}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/domains"} className="ui icon button">
              <Icon name="arrow left" />
            </Link>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.saveDomain}>
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <Header as="h4">Domain Details</Header>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Domain"
                  placeholder="Domain"
                  id="domain"
                  required
                  value={domain.domain}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <div className="required field">
                  <label htmlFor="expireDate">Expire Date</label>
                  <DateInput
                    name="expireDate"
                    id="expireDate"
                    placeholder="Expire Date"
                    dateFormat="DD.MM.YYYY"
                    value={domain.expireDate}
                    iconPosition="left"
                    onChange={this.handleChangeDate}
                  />
                </div>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Checkbox}
                  label="Hosting"
                  id="hosting"
                  checked={domain.hosting === true}
                  onChange={this.handleChangeCheckbox}
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Domain Details</Header>
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  options={this.state.customers}
                  label={{
                    children: "Customer",
                    htmlFor: "form-select-control-customer",
                  }}
                  placeholder="Customer"
                  required
                  search
                  autoComplete="off"
                  value={this.state.domain.customerId}
                  onChange={(e, { value }) =>
                    this.handleChangeSelect("customerId", value)
                  }
                  searchInput={{ id: "form-select-control-customer" }}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>

          <Grid columns={2}>
            <Grid.Column>
              {this.state.existingDomain && (
                <Button color="red" onClick={this.deleteDomain}>
                  Delete
                </Button>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button primary>Save</Button>
            </Grid.Column>
          </Grid>
        </Form>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Domain)

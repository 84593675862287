import React from "react"

import PrivateRoute from "../components/privateRoute"
import { Switch, Redirect } from "react-router-dom"
import HeaderNavigation from "../components/headerNavigation"
import SideNavigation from "../components/sideNavigation"

import Dashboard from "./dashboard"

import Domains from "./domains"
import Domain from "./domains/domain"

import Customers from "./customers"
import Customer from "./customers/customer"

import Employees from "./employees"
import Employee from "./employees/employee"

import Projects from "./projects"
import Timetracking from "./timetracking"
import Invoices from "./invoices"

class Framework extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SideNavigation />
        <div className="apinvoice-c-wrapper">
          <HeaderNavigation />
          <Switch>
            <Redirect from="/" to="/dashboard" exact />
            <PrivateRoute path="/dashboard" component={Dashboard} />

            <PrivateRoute path="/customers" exact component={Customers} />
            <PrivateRoute path="/customers/new" exact component={Customer} />
            <PrivateRoute
              path="/customers/:customerId"
              exact
              component={Customer}
            />

            <PrivateRoute path="/domains" exact component={Domains} />
            <PrivateRoute path="/domains/new" component={Domain} />
            <PrivateRoute path="/domains/:domainId" component={Domain} />

            <PrivateRoute path="/employees" exact component={Employees} />
            <PrivateRoute path="/employees/new" component={Employee} />
            <PrivateRoute path="/employees/:employeeId" component={Employee} />

            <PrivateRoute path="/projects" exact component={Projects} />
            <PrivateRoute path="/timetracking" exact component={Timetracking} />
            <PrivateRoute path="/invoices" exact component={Invoices} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </React.Fragment>
    )
  }
}

export default Framework

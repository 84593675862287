import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Header,
  Button,
  Form,
  Input,
  Grid,
  Loader,
  Dimmer,
  Icon,
  Select,
} from "semantic-ui-react"

import { Link } from "react-router-dom"

import { countryOptions } from "../../../common/countries"
import { currencyOptions } from "../../../common/currencies"

import {
  createCustomer,
  getCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../../redux/actions/customerActions"

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
    customerCreated: state.customer.created,
    customerRemoved: state.customer.removed,
    customerUpdated: state.customer.updated,
    customer: state.customer.customer,
    loading: state.customer.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createCustomer: customer => dispatch(createCustomer(customer)),
    getCustomer: customerId => dispatch(getCustomer(customerId)),
    deleteCustomer: customerId => dispatch(deleteCustomer(customerId)),
    updateCustomer: (customerId, payload) =>
      dispatch(updateCustomer(customerId, payload)),
  }
}

class Customer extends Component {
  state = {
    customer: {
      companyName: "",
      address: "",
      zip: "",
      city: "",
      country: "",
      currency: "",
      name: "",
      email: "",
      phone: "",
    },
    error: null,
    existingCustomer: false,
  }

  componentWillMount() {
    if (this.props.match.params.customerId) {
      this.props.getCustomer(this.props.match.params.customerId)
      this.setState({ existingCustomer: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.customerCreated ||
        nextProps.customerRemoved ||
        nextProps.customerUpdated
      ) {
        this.props.history.push("/customers")
      } else {
        if (this.props.customer !== nextProps.customer) {
          this.setState({
            customer: { ...nextProps.customer },
          })
        }
      }
    }
  }

  saveCustomer = e => {
    e.preventDefault()

    if (this.state.existingCustomer) {
      this.props.updateCustomer(
        this.props.match.params.customerId,
        this.state.customer
      )
    } else {
      this.props.createCustomer(this.state.customer)
    }
  }

  deleteCustomer = e => {
    e.preventDefault()
    this.props.deleteCustomer(this.props.match.params.customerId)
  }

  handleChange = e => {
    this.setState({
      customer: {
        ...this.state.customer,
        [e.target.id]: e.target.value,
      },
      error: null,
    })
  }

  handleChangeSelect = (name, value) => {
    this.setState({
      customer: {
        ...this.state.customer,
        [name]: value,
      },
      error: null,
    })
  }

  render() {
    if (this.props.loading)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )

    const { customer } = this.state
    const pageTitle = this.state.existingCustomer
      ? "Edit Customer"
      : "New Customer"

    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">{pageTitle}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/customers"} className="ui icon button">
              <Icon name="arrow left" />
            </Link>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.saveCustomer} autoComplete="off">
          <input type="hidden" value="preventAutofill" />
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <Header as="h4">Company Details</Header>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Company name"
                  placeholder="Company name"
                  id="companyName"
                  required
                  value={customer.companyName}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Address"
                  placeholder="Address"
                  id="address"
                  required
                  value={customer.address}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="ZIP"
                  placeholder="ZIP"
                  id="zip"
                  required
                  value={customer.zip}
                  onChange={this.handleChange}
                />
                <Form.Field
                  control={Input}
                  label="City"
                  placeholder="City"
                  id="city"
                  required
                  value={customer.city}
                  onChange={this.handleChange}
                />
                <Form.Field
                  control={Select}
                  options={countryOptions}
                  label={{
                    children: "Select country",
                    htmlFor: "form-select-control-country",
                  }}
                  placeholder="Country"
                  search
                  autoComplete="off"
                  onChange={(e, { value }) =>
                    this.handleChangeSelect("country", value)
                  }
                  searchInput={{ id: "form-select-control-country" }}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Select}
                  options={currencyOptions}
                  label={{
                    children: "Select currency",
                    htmlFor: "form-select-control-currency",
                  }}
                  placeholder="Country"
                  search
                  autoComplete="off"
                  onChange={(e, { value }) =>
                    this.handleChangeSelect("currency", value)
                  }
                  searchInput={{ id: "form-select-control-currency" }}
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Customer Details</Header>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Name"
                  placeholder="Name"
                  id="name"
                  value={customer.name}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  control={Input}
                  label="Email"
                  placeholder="Email"
                  id="email"
                  value={customer.email}
                  onChange={this.handleChange}
                />
                <Form.Field
                  control={Input}
                  label="Phone"
                  placeholder="Phone"
                  id="phone"
                  value={customer.phone}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Grid.Column>
          </Grid>

          <Grid columns={2}>
            <Grid.Column>
              {this.state.existingCustomer && (
                <Button color="red" onClick={this.deleteCustomer}>
                  Delete
                </Button>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button primary>Save</Button>
            </Grid.Column>
          </Grid>
        </Form>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer)

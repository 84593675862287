import React, { Component } from "react";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {};
}

class Page404 extends Component {
  render() {
    return <div>Page404</div>;
  }
}

export default connect(mapStateToProps)(Page404);

export const currencyOptions = [
  {
    text: "EUR",
    key: "eur",
    value: "eur",
  },
  {
    text: "USD",
    key: "usd",
    value: "usd",
  },
]

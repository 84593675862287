export const countryOptions = [
  {
    text: "Slovenia",
    key: "si",
    value: "si",
    flag: "si",
  },
  {
    text: "United Kingdom",
    key: "gb",
    value: "gb",
    flag: "gb",
  },
  {
    text: "United States",
    key: "us",
    value: "us",
    flag: "us",
  },
]

import React from "react"
import { Menu } from "semantic-ui-react"
import { logout } from "../../redux/actions/authActions"
import { connect } from "react-redux"

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
  }
}

const mapStateToProps = state => {
  return {
    firebase: state.firebase,
    firebaseProfile: state.firebase.profile,
  }
}

const HeaderNavigation = ({ logout }) => {
  return (
    <Menu secondary pointing>
      <div className="item">APTO d.o.o.</div>
      <Menu.Menu position="right">
        <Menu.Item name="logout" onClick={logout} />
      </Menu.Menu>
    </Menu>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavigation)

import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { Container, Header, Icon, Button, Form, Grid } from "semantic-ui-react"
import { signIn } from "../../redux/actions/authActions"

const mapStateToProps = state => {
  return {
    firebaseAuth: state.firebase.auth,
    authError: state.auth.authError,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials)),
  }
}

class Login extends Component {
  state = {
    email: "",
    password: "",
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    })
  }

  login = e => {
    e.preventDefault()
    this.props.signIn(this.state)
  }

  render() {
    if (this.props.firebaseAuth.isEmpty !== true)
      return <Redirect to="/dashboard" />
    return (
      <div className="apinvoice-c-login">
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as="h1" icon textAlign="center">
                  <Icon name="users" circular inverted />
                  <Header.Content className="apinvoice-c-login__header">
                    APInvoice
                  </Header.Content>
                  <Form onSubmit={this.login}>
                    <Form.Field className="apinvoice-c-login__field">
                      <label>Email</label>
                      <input
                        placeholder="email"
                        id="email"
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Form.Field className="apinvoice-c-login__field">
                      <label>Password</label>
                      <input
                        type="password"
                        id="password"
                        placeholder="password"
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                    <Button type="submit" primary>
                      Login
                    </Button>
                  </Form>
                  {this.props.authError && (
                    <div>{this.props.authError.message}</div>
                  )}
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

import http from "../../lib/httpService"
import moment from "moment"

export const createDomain = domain => {
  return async dispatch => {
    try {
      domain.expireDate = moment(domain.expireDate, "DD.MM.YYYY").valueOf()

      await http.put("/domains", domain)

      dispatch({ type: "CREATE_DOMAIN" })
    } catch (err) {
      dispatch({ type: "CREATE_DOMAIN_ERROR", payload: err })
    }
  }
}

export const getDomain = domainId => {
  return async dispatch => {
    dispatch({ type: "GET_DOMAIN" })
    try {
      const response = await http.get(`/domains/${domainId}`)
      response.expireDate = moment(response.expireDate).format("DD.MM.YYYY")
      dispatch({ type: "GET_DOMAIN_FULFILLED", payload: response })
    } catch (err) {
      dispatch({ type: "GET_DOMAIN_ERROR", payload: err })
    }
  }
}

export const updateDomain = (domainId, domainData) => {
  return async dispatch => {
    try {
      domainData.expireDate = moment(
        domainData.expireDate,
        "DD.MM.YYYY"
      ).valueOf()
      const response = await http.post(`/domains/${domainId}`, domainData)
      dispatch({ type: "UPDATE_DOMAIN", payload: response })
    } catch (err) {
      dispatch({ type: "UPDATE_DOMAIN_ERROR", payload: err })
    }
  }
}

export const renewDomain = domainId => {
  return async dispatch => {
    dispatch({ type: "RENEW_DOMAIN" })
    try {
      const response = await http.get(`/domains/renew/${domainId}`)
      dispatch({ type: "RENEW_DOMAIN_FULFILLED", payload: response })
    } catch (err) {
      dispatch({ type: "RENEW_DOMAIN_ERROR", payload: err })
    }
  }
}

export const deleteDomain = domainId => {
  return async dispatch => {
    try {
      const response = await http.delete(`/domains/${domainId}`)
      dispatch({ type: "DELETE_DOMAIN", payload: response })
    } catch (err) {
      dispatch({ type: "DELETE_DOMAIN_ERROR", payload: err })
    }
  }
}

export const getDomains = () => {
  return async dispatch => {
    dispatch({ type: "GET_DOMAINS" })
    try {
      const response = await http.get("/domains")
      dispatch({ type: "GET_DOMAINS_FULFILLED", payload: response })
    } catch (err) {
      dispatch({ type: "GET_DOMAINS_ERROR", payload: err })
    }
  }
}

import { combineReducers } from "redux"
import { firebaseReducer } from "react-redux-firebase"
import authReducer from "./authReducer"
import employeeReducer from "./employeeReducer"
import customerReducer from "./customerReducer"
import domainReducer from "./domainReducer"

export default combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  customer: customerReducer,
  domain: domainReducer,
  firebase: firebaseReducer,
})

const INITIAL_STATE = {
  created: false,
  removed: false,
  updated: false,
  loading: false,
  error: null,
  domains: [],
  domain: {},
}

const domainReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_DOMAINS":
      console.log("Loading domains")
      return { ...state, domains: {}, loading: true }
    case "GET_DOMAINS_FULFILLED":
      console.log("Get domains", action.payload)
      return {
        ...state,
        domains: action.payload,
        created: false,
        removed: false,
        updated: false,
        loading: false,
        error: null,
      }
    case "GET_DOMAINS_ERROR":
      console.log("Get domains ERROR", action.payload)
      return {
        ...state,
        domains: [],
        created: false,
        removed: false,
        updated: false,
        error: action.payload,
      }
    case "GET_DOMAIN":
      console.log("Loading domain")
      return { ...state, domain: {}, loading: true }
    case "GET_DOMAIN_FULFILLED":
      console.log("Get domain", action.payload)
      return { ...state, domain: action.payload, loading: false }
    case "RENEW_DOMAIN":
      console.log("Renewing domain")
      return { ...state, loading: true }
    case "RENEW_DOMAIN_FULFILLED":
      console.log("Renew domain", action.payload)
      const domains = state.domains.map(domain => {
        if (domain.id === action.payload.id) {
          return (domain = action.payload)
        }

        return domain
      })
      domains.sort((a, b) => a.expireDate - b.expireDate)

      return { ...state, domains: domains, loading: false }
    case "CREATE_DOMAIN":
      console.log("Created domain", action.payload)
      return { ...state, domain: {}, created: true }
    case "UPDATE_DOMAIN":
      console.log("Employee updated", action.payload)
      return { ...state, domain: {}, updated: true }
    case "DELETE_DOMAIN":
      console.log("Employee removed", action.payload)
      return { ...state, domain: {}, removed: true }
    case "CREATE_DOMAIN_ERROR":
      console.log("Create domain error ", action.payload)
      return {
        ...state,
        created: { created: false, error: action.payload },
      }
    default:
      return state
  }
}

export default domainReducer

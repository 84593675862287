import React, { Component } from "react"
import { connect } from "react-redux"
import {
  Header,
  Button,
  Form,
  Input,
  TextArea,
  Grid,
  Loader,
  Dimmer,
  Icon,
} from "semantic-ui-react"

import { Link } from "react-router-dom"

import {
  createEmployee,
  getEmployee,
  deleteEmployee,
  updateEmployee,
} from "../../../redux/actions/employeeActions"

const mapStateToProps = state => {
  return {
    firebaseProfile: state.firebase.profile,
    employeeCreated: state.employee.created,
    employeeRemoved: state.employee.removed,
    employeeUpdated: state.employee.updated,
    employee: state.employee.employee,
    loading: state.employee.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createEmployee: employee => dispatch(createEmployee(employee)),
    getEmployee: employeeId => dispatch(getEmployee(employeeId)),
    deleteEmployee: employeeId => dispatch(deleteEmployee(employeeId)),
    updateEmployee: (employeeId, payload) =>
      dispatch(updateEmployee(employeeId, payload)),
  }
}

class Employee extends Component {
  state = {
    employee: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      notes: "",
    },
    error: null,
    existingEmployee: false,
  }

  componentWillMount() {
    if (this.props.match.params.employeeId) {
      this.props.getEmployee(this.props.match.params.employeeId)
      this.setState({ existingEmployee: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.employeeCreated ||
        nextProps.employeeRemoved ||
        nextProps.employeeUpdated
      ) {
        this.props.history.push("/employees")
      } else {
        if (this.props.employee !== nextProps.employee) {
          this.setState({
            employee: {
              firstName: nextProps.employee.firstName,
              lastName: nextProps.employee.lastName,
              phone: nextProps.employee.phone,
              email: nextProps.employee.email,
              notes: nextProps.employee.notes,
            },
          })
        }
      }
    }
  }

  saveEmployee = e => {
    e.preventDefault()

    if (this.state.existingEmployee) {
      this.props.updateEmployee(
        this.props.match.params.employeeId,
        this.state.employee
      )
    } else {
      this.props.createEmployee(this.state.employee)
    }
  }

  deleteEmployee = e => {
    e.preventDefault()
    this.props.deleteEmployee(this.props.match.params.employeeId)
  }

  handleChange = e => {
    this.setState({
      employee: {
        ...this.state.employee,
        [e.target.id]: e.target.value,
      },
      error: null,
    })
  }

  render() {
    if (this.props.loading)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )

    const { employee } = this.state
    const pageTitle = this.state.existingEmployee
      ? "Edit Employee"
      : "New Employee"

    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">{pageTitle}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/employees"} className="ui icon button">
              <Icon name="arrow left" />
            </Link>
          </Grid.Column>
        </Grid>

        <Form onSubmit={this.saveEmployee}>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="First name"
              placeholder="First name"
              id="firstName"
              required
              value={employee.firstName}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              label="Last name"
              placeholder="Last name"
              id="lastName"
              required
              value={employee.lastName}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Email"
              placeholder="Email"
              id="email"
              value={employee.email}
              onChange={this.handleChange}
            />
            <Form.Field
              control={Input}
              label="Phone"
              placeholder="Phone"
              id="phone"
              value={employee.phone}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={TextArea}
              label="Notes"
              placeholder="Notes..."
              id="notes"
              value={employee.notes}
              onChange={this.handleChange}
            />
          </Form.Group>
          <Grid columns={2}>
            <Grid.Column>
              {this.state.existingEmployee && (
                <Button color="red" onClick={this.deleteEmployee}>
                  Delete
                </Button>
              )}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button primary>Save</Button>
            </Grid.Column>
          </Grid>
        </Form>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employee)

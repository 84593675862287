import React, { Component } from "react"
import {
  Table,
  Header,
  Icon,
  Button,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { getEmployees } from "../../redux/actions/employeeActions"

const mapStateToProps = state => {
  return {
    employees: state.employee.employees,
    loading: state.employee.loading,
    firebaseProfile: state.firebase.profile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: () => dispatch(getEmployees()),
  }
}

class Employees extends Component {
  componentWillMount() {
    this.props.getEmployees()
  }

  render() {
    if (this.props.loading)
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      )
    const { employees } = this.props
    return (
      <React.Fragment>
        <Grid columns={2}>
          <Grid.Column>
            <Header as="h3">Employees</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Link to={"/employees/new"} className="ui icon button">
              <Icon name="plus" />
            </Link>
          </Grid.Column>
        </Grid>

        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Phone</Table.HeaderCell>
              <Table.HeaderCell>Notes</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {employees.length > 0 &&
              employees.map((employee, id) => (
                <Table.Row key={employee.id}>
                  <Table.Cell>
                    {`${employee.firstName} ${employee.lastName}`}
                  </Table.Cell>
                  <Table.Cell>{employee.email}</Table.Cell>
                  <Table.Cell>{employee.phone}</Table.Cell>
                  <Table.Cell>{employee.notes}</Table.Cell>
                  <Table.Cell>
                    <Button
                      basic
                      color="green"
                      onClick={() =>
                        this.props.history.push(`/employees/${employee.id}`)
                      }
                    >
                      Edit
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </React.Fragment>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employees)
